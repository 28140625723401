import {motion} from "framer-motion";
import React from "react";


function TransitionLoader(){

    return (
        <div className={"d-flex justify-content-center align-items-center h-100 border"}>
            <motion.div className="loading"
                        key={"loading"}
                        initial={{opacity:"5%", scale: "100%"}}
                        animate={{opacity:"100%",scale:"100%"}}
                        transition = {{duration: 0.75, ease:"easeIn"}}
                        exit={{opacity:"10%", y:"0%", transition: {duration:"0.3", ease: "easeOut"}}}
            >
                <div className={"d-flex justify-content-center align-items-center h-100"}>
                    <p className="fs-5">Loading...</p>
                </div>
            </motion.div>
        </div>
    )
}

export default TransitionLoader