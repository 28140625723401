import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {RovaProvider,} from "./context/AuthContext";
import {Toaster} from "react-hot-toast";
import "/public/scss/styles.scss"
import {LayoutProvider} from "./context/LayoutContext";
import React, {Suspense,} from "react";
import CustomLinksPage from "./pages/CustomLinks";
import TransitionLoader from "./library/components/layout/TransitionLoader";
const SandboxPage = React.lazy(() => import("./pages/Sandbox"))
const HomePage = React.lazy(() => import("./pages/Home"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"))
const LinksPage = React.lazy(() => import("./pages/Links"))
const LinkPage = React.lazy(() => import ("./pages/Link"))
const Protected = React.lazy(() => import("./library/Protected"))
const QRCodesPage = React.lazy(() => import("./pages/QRCodes"))
const QRCodePage = React.lazy(() => import("./pages/QRCode"))
const AnalyticsPage = React.lazy(() => import("./pages/Analytics"))
const PasswordResetPage = React.lazy(() => import("./pages/PasswordResetPage"))
const NewPasswordPage = React.lazy(() => import("./pages/NewPasswordPage"))
const SignupPage = React.lazy(() => import("./pages/SignupPage"))
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"))
const DevelopersPage = React.lazy(() => import("./pages/DevelopersPage"))
const PricingTablePage = React.lazy(() => import("./pages/payments/PricingTablePage"))
const ConfirmationPage = React.lazy(() => import("./pages/payments/ConfirmationPage"))
const LogoutPage = React.lazy(() => import("./pages/LogoutPage"))
const OauthSignInPage = React.lazy(() => import("./pages/OauthPage"))
import * as Sentry from "@sentry/react";




function App() {

    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <>

        <BrowserRouter>
            <RovaProvider>
                <LayoutProvider>
                    <Suspense fallback={
                        <TransitionLoader/>}
                    >
                       <SentryRoutes>
                           <Route path="/login" element={<LoginPage />} />
                           <Route path="/password-reset" element={<PasswordResetPage />} />
                           <Route path="/new-password" element={<NewPasswordPage />} />
                           <Route path="/signup" element={<SignupPage />} />
                           <Route path="/sandbox" element={<SandboxPage/>}/>
                           <Route path="/payments/pricing" element={<Protected><PricingTablePage/></Protected>}/>
                           <Route path="/payments/confirmation" element={<ConfirmationPage/>}/>
                           <Route path="/logout" element={<LogoutPage/>} />
                           <Route path="/oauth-signin" element={<OauthSignInPage/>} />
                           {/*authentication protected routes*/}
                           <Route path = "/" element={<Protected><HomePage/></Protected>}/>
                           <Route path = "/links" element={<Protected><LinksPage/></Protected>}/>
                           <Route path = "/link/:linkKey" element={<Protected><LinkPage/></Protected>}/>
                           <Route path = "/qr-codes" element={<Protected><QRCodesPage/></Protected>}/>
                           <Route path = "/qr-code/:linkKey" element={<Protected><QRCodePage/></Protected>}/>
                           <Route path = "/custom-links" element={<Protected><CustomLinksPage/></Protected>}/>
                           <Route path = "/analytics" element={<Protected><AnalyticsPage/></Protected>}/>
                           <Route path = "/profile" element={<Protected><ProfilePage/></Protected>}/>
                           <Route path = "/developers" element={<Protected><DevelopersPage/></Protected>}/>

                           <Route path="*" element={<Navigate to="/" />} />
                       </SentryRoutes>
                    </Suspense>
                </LayoutProvider>
                <Toaster position={"top-right"}/>
            </RovaProvider>
        </BrowserRouter>
    </>
  )
}

export default App
