import {useLayoutData} from "../../../context/LayoutContext";


function RightSubMenu(){
    const {layoutConfig} = useLayoutData()

    return (
        <>
            {(layoutConfig && layoutConfig.includeRightSubMenu) && (
                <div id="c-main-tools" datatype="menu" className="c-main-tools">
                    {/*Layer 5 - Main column - tools submenus*/}

                    {/*Tools Menu 1*/}
                    <div id="c-main-tools-submenu-0" datatype="c-main-tools-submenu"
                         className="c-main-tools-submenu">
                        <h2 className="p-3">Styles 1</h2>
                        <form>
                            <ul>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100 d-flex justify-content-between align-items-center"
                                           htmlFor="class-search">
                                        <h3>Classes</h3>
                                        <button aria-label="Button name">+ Add</button>
                                    </label>
                                    <input className="w-100" type="text" id="class-search" name="class-search"
                                           placeholder="Create or find a class"/>
                                </li>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100 d-flex justify-content-between align-items-center"
                                           htmlFor="class-search">
                                        <h3>States</h3>
                                        <button aria-label="Button name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
                                                <path
                                                    d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                                <path
                                                    d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                            </svg>
                                        </button>
                                    </label>
                                </li>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100" htmlFor="class-search">
                                        <h3>Selectors (1)</h3>
                                    </label>
                                    <input className="w-100" type="text" id="class-search" name="class-search"
                                           placeholder="Placeholder"/>
                                </li>
                            </ul>
                        </form>
                    </div>

                    {/*Tools Menu 2*/}
                    <div id="c-main-tools-submenu-1" datatype="c-main-tools-submenu"
                         className="c-main-tools-submenu">
                        <h2 className="p-3">Styles 2</h2>
                        <form>
                            <ul>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100 d-flex justify-content-between align-items-center"
                                           htmlFor="class-search">
                                        <h3>Classes</h3>
                                        <button aria-label="Button name">+ Add</button>
                                    </label>
                                    <input className="w-100" type="text" id="class-search" name="class-search"
                                           placeholder="Create or find a class"/>
                                </li>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100 d-flex justify-content-between align-items-center"
                                           htmlFor="class-search">
                                        <h3>States</h3>
                                        <button aria-label="Button name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
                                                <path
                                                    d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                                <path
                                                    d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                            </svg>
                                        </button>
                                    </label>
                                </li>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100" htmlFor="class-search">
                                        <h3>Selectors (1)</h3>
                                    </label>
                                    <input className="w-100" type="text" id="class-search" name="class-search"
                                           placeholder="Placeholder"/>
                                </li>
                            </ul>
                        </form>
                    </div>

                    {/*Tools Menu 3*/}
                    <div id="c-main-tools-submenu-2" datatype="c-main-tools-submenu"
                         className="c-main-tools-submenu">
                        <h2 className="p-3">Styles 3</h2>
                        <form>
                            <ul>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100 d-flex justify-content-between align-items-center"
                                           htmlFor="class-search">
                                        <h3>Classes</h3>
                                        <button aria-label="Button name">+ Add</button>
                                    </label>
                                    <input className="w-100" type="text" id="class-search" name="class-search"
                                           placeholder="Create or find a class"/>
                                </li>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100 d-flex justify-content-between align-items-center"
                                           htmlFor="class-search">
                                        <h3>States</h3>
                                        <button aria-label="Button name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
                                                <path
                                                    d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                                <path
                                                    d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                            </svg>
                                        </button>
                                    </label>
                                </li>
                                <li className="pb-3 mb-3 mx-3 border-bottom">
                                    <label className="w-100" htmlFor="class-search">
                                        <h3>Selectors (1)</h3>
                                    </label>
                                    <input className="w-100" type="text" id="class-search" name="class-search"
                                           placeholder="Placeholder"/>
                                </li>
                            </ul>
                        </form>
                    </div>

                </div>
            )}
        </>
    )
}

export default RightSubMenu