import {SubscriptionRole} from "../../types/AppObjectTypes";


export const SubscriptionRoles:SubscriptionRole[] = [
    {
        role: "no_subscription",
        linkCap:3,
        qrCap: 1,
        monthly:false
    },
    {
        role: "personal",
        linkCap: 500,
        qrCap: 10,
        monthly:true
    },
    {
        role: "business",
        linkCap: 10000,
        qrCap: 300,
        monthly: true
    },
    {
        role: "unlimited",
        linkCap: 1000000,
        qrCap: 1000000,
        monthly: true
    }
]