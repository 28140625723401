import {useLayoutData} from "../../../context/LayoutContext";
import { FaChevronRight } from "react-icons/fa";
import {useState} from "react";
import classNames from "classnames";

function LeftSideBar(){

    const {toggleMenu,toggleTag, layoutConfig} = useLayoutData()
    const [rotated, setRotated] = useState<"default"|"rotated"|"unrotated">("default")

    return (
        <>
            {(layoutConfig && layoutConfig.includeLeftSidebar) ? (
                <nav id="c-btn-col"
                     datatype="menu"
                     className="c-btn-col c-d-flex flex-column justify-content-between align-items-center"
                >
                    <div></div>
                    <ul className="c-d-flex flex-column gap-1 py-3 border-top">
                        {layoutConfig.includeLeftSubMenu && (
                            <li>
                                <div >
                                    <button
                                        onClick={() => {
                                            setRotated(prevState => {
                                                if(prevState === "default" || prevState === "rotated"){
                                                    return "unrotated"
                                                }else{
                                                    return "rotated"
                                                }
                                            })
                                            toggleMenu(['c-elements','c-elements-header'],{})
                                        }}
                                        aria-label="Button name"
                                        className={classNames([{"rotated":rotated === "rotated"},{"unrotated":rotated=== "unrotated"}],"icon")}>
                                        <FaChevronRight/>
                                    </button>
                                </div>
                            </li>
                        )}
                        {/*<li>*/}
                        {/*    <button onClick={() => toggleTag()} aria-label="Button name" className="icon">*/}
                        {/*        TAG*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <button aria-label="Button name" className="icon">*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                        {/*             className="bi bi-stack" viewBox="0 0 16 16">*/}
                        {/*            <path*/}
                        {/*                d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>*/}
                        {/*            <path*/}
                        {/*                d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>*/}
                        {/*        </svg>*/}

                        {/*    </button>*/}
                        {/*</li>*/}
                    </ul>
                </nav>
            ):<></>}
        </>
    )
}
export default LeftSideBar