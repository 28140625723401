import {DarkTheme, LayoutConfiguration, LightTheme} from "../../types/LayoutTypes";

export const lightTheme:LightTheme = {
    "--bg-primary" :        "#ffffff",
    "--bg-secondary" :      "#f9f9f9",
    "--font-primary" :      "#0a0e30",
    "--font-secondary" :    "#a4aabd",
    "--accent-primary" :    "#00a3fe",
    "--accent-secondary" :  "#f9f9f9"
}

export const darkTheme:DarkTheme = {
    "--bg-primary" :        "#292f46",
    "--bg-secondary" :      "#a4aabd",
    "--font-primary" :      "#ffffff",
    "--font-secondary" :    "#f3f3f6",
    "--accent-primary" :    "#00a3fe",
    "--accent-secondary" :  "#f9f9f9",
}


//initial states
export const initialLightMode:boolean = true;

export const initialMenuState = {
    "c-elements" : true,
    "c-elements-header" : true,
    "c-btn-col" : true,
    "c-btn-col-header" : true,
    "c-main-header" : true,
    "c-main-display-footer" : true,
    "c-main-display-popup" : false,
    "c-main-btn-col" : true,
    "c-main-tools" : true,
    "c-main-mobile-footer" : true
};

export const initialSubMenuState = {
    "c-main-tools" : 0,
    "c-main-display-popup" : 0
}
export const initialTagMode = false

//layout configuration
export const layoutConfiguration:LayoutConfiguration = {
    includeLeftSidebar: false,
    includeLeftSubMenu: true,
    includeMainPopup1: false,
    includeMainPopup2: false,
    includeMainPopup3: false,
    includeMainPopup4: false,
    includeMainPopupNav: true,
    includeRightSideBar: false,
    includeRightSubMenu: false,
    includeTopBar: true
}