


function HatcheryConnectLogo(props:any){

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={501}
            height={86}
            viewBox="0 0 501 86"
            xmlSpace="preserve"
            {...props}
        >
            <desc>{"Created with Fabric.js 3.6.6"}</desc>
            <defs />
            <g transform="matrix(1 0 0 1 250.2 41.2)">
                <g style={{}}>
                    <g transform="matrix(1 0 0 1 -132.75 -3)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-292.45, -422.2)"
                            d="M 302.6 416.7 c 1.6 1.7 2.4 4 2.4 6.8 V 439 H 297 v -13.1 c 0 -1.4 -0.4 -2.5 -1.1 -3.3 c -0.7 -0.8 -1.8 -1.2 -3.1 -1.2 c -1.5 0 -2.6 0.6 -3.5 1.6 c -0.9 1 -1.3 2.3 -1.3 4 V 439 h -8.1 v -33.6 h 8.1 v 13.1 c 0.9 -1.4 2 -2.5 3.4 -3.2 c 1.4 -0.7 2.9 -1.1 4.7 -1.1 C 298.8 414.2 301 415.1 302.6 416.7 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 -104.9 1.55)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-320.3, -426.75)"
                            d="M 329.3 416.5 c 1.9 1.6 2.8 3.7 2.8 6.5 V 439 h -7.9 v -2.8 c -1.6 2.1 -4.1 3.1 -7.4 3.1 c -2.6 0 -4.6 -0.7 -6.1 -2.1 c -1.5 -1.4 -2.2 -3.2 -2.2 -5.5 c 0 -2.3 0.8 -4.1 2.4 -5.4 c 1.6 -1.3 3.9 -2 6.9 -2 h 6.4 V 424 c 0 -1.2 -0.4 -2.1 -1.2 -2.7 c -0.8 -0.6 -2 -0.9 -3.5 -0.9 c -1 0 -2.2 0.2 -3.5 0.5 c -1.3 0.3 -2.6 0.8 -3.9 1.4 l -2.2 -5.4 c 2 -0.9 3.9 -1.6 5.7 -2 c 1.8 -0.4 3.7 -0.7 5.7 -0.7 C 324.8 414.2 327.5 415 329.3 416.5 L 329.3 416.5 z M 322.6 433 L 322.6 433 c 0.9 -0.6 1.5 -1.3 1.7 -2.3 v -2.1 h -4.8 c -2.3 0 -3.4 0.8 -3.4 2.5 c 0 0.8 0.3 1.5 0.9 2 c 0.6 0.5 1.4 0.7 2.4 0.7 C 320.6 433.9 321.7 433.6 322.6 433 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 -80.8 -1.1)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-344.4, -424.1)"
                            d="M 353.5 437.7 c -0.9 0.5 -1.9 0.9 -3.1 1.2 s -2.4 0.4 -3.6 0.4 c -2.4 0 -4.4 -0.7 -5.9 -2.1 c -1.5 -1.4 -2.2 -3.3 -2.2 -5.7 V 421 h -3.4 v -5.3 h 3.4 v -6.8 h 8 v 6.8 h 6.3 v 5.3 h -6.3 v 9.2 c 0 1.7 0.7 2.6 2.1 2.6 c 0.9 0 1.9 -0.3 3.1 -0.8 L 353.5 437.7 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 -58.15 1.55)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-367.05, -426.75)"
                            d="M 368.2 420.9 c -1.4 0 -2.6 0.6 -3.5 1.7 c -0.9 1.1 -1.4 2.5 -1.4 4.3 c 0 1.8 0.5 3.2 1.4 4.3 c 0.9 1.1 2.1 1.6 3.5 1.6 c 2.2 0 3.8 -0.9 4.8 -2.8 l 5.9 3.4 c -1 1.9 -2.4 3.3 -4.3 4.3 c -1.9 1 -4.2 1.5 -6.8 1.5 c -2.5 0 -4.7 -0.5 -6.6 -1.5 c -1.9 -1 -3.4 -2.5 -4.4 -4.3 c -1 -1.9 -1.6 -4 -1.6 -6.5 c 0 -2.5 0.5 -4.7 1.6 -6.6 c 1.1 -1.9 2.5 -3.4 4.5 -4.4 c 1.9 -1 4.1 -1.6 6.6 -1.6 c 2.4 0 4.6 0.5 6.5 1.5 c 1.9 1 3.4 2.4 4.4 4.3 l -5.8 3.4 C 371.9 421.8 370.3 420.9 368.2 420.9 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 -29.9 -3)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-395.3, -422.2)"
                            d="M 405.5 416.7 c 1.6 1.7 2.4 4 2.4 6.8 V 439 h -8.1 v -13.1 c 0 -1.4 -0.4 -2.5 -1.1 -3.3 c -0.7 -0.8 -1.8 -1.2 -3.1 -1.2 c -1.5 0 -2.6 0.6 -3.5 1.6 c -0.9 1 -1.3 2.3 -1.3 4 V 439 h -8.1 v -33.6 h 8.1 v 13.1 c 0.9 -1.4 2 -2.5 3.4 -3.2 c 1.4 -0.7 2.9 -1.1 4.7 -1.1 C 401.8 414.2 403.9 415.1 405.5 416.7 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 -1.3 1.55)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-423.9, -426.75)"
                            d="M 433.3 417.8 c 2.1 2.4 3.2 5.6 3.2 9.7 c 0 0.6 0 1.1 0 1.4 h -17 c 0.4 1.4 1.1 2.5 2 3.2 c 1 0.7 2.1 1.1 3.5 1.1 c 1.1 0 2.1 -0.2 3.1 -0.6 c 1 -0.4 1.9 -1 2.7 -1.9 l 4.2 4.2 c -1.3 1.4 -2.8 2.5 -4.6 3.2 c -1.8 0.7 -3.8 1.1 -6.1 1.1 c -2.6 0 -4.9 -0.5 -6.9 -1.5 c -2 -1 -3.5 -2.5 -4.5 -4.3 c -1 -1.9 -1.6 -4 -1.6 -6.5 c 0 -2.5 0.5 -4.7 1.6 -6.6 c 1.1 -1.9 2.5 -3.4 4.5 -4.4 c 1.9 -1 4.2 -1.6 6.7 -1.6 C 428.1 414.3 431.2 415.4 433.3 417.8 L 433.3 417.8 z M 428.7 424.6 L 428.7 424.6 c 0 -1.4 -0.4 -2.6 -1.2 -3.4 c -0.8 -0.9 -1.9 -1.3 -3.3 -1.3 c -1.3 0 -2.3 0.4 -3.2 1.3 c -0.9 0.8 -1.4 2 -1.7 3.4 H 428.7 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 23.45 1.4)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-448.65, -426.6)"
                            d="M 452 415.3 c 1.3 -0.8 2.9 -1.1 4.6 -1.1 v 7.4 c -0.3 0 -0.7 0 -1.2 0 c -1.9 0 -3.4 0.4 -4.6 1.3 c -1.2 0.9 -1.9 2.1 -2 3.6 V 439 h -8.1 v -24.5 h 8.1 v 4.1 C 449.6 417.2 450.7 416.1 452 415.3 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 45.95 6.15)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-471.15, -431.35)"
                            d="M 470.6 446.5 c -1.6 1.1 -3.5 1.7 -5.8 1.7 c -1.4 0 -2.6 -0.2 -3.8 -0.6 c -1.2 -0.4 -2.3 -1 -3.4 -1.9 l 3.3 -5.6 c 0.6 0.5 1.1 0.8 1.6 1 c 0.5 0.2 1.1 0.3 1.6 0.3 c 1.3 0 2.3 -0.6 2.9 -1.9 l 0.6 -1.1 l -10.1 -23.9 h 8.3 l 5.8 15.9 l 5.2 -15.9 h 8 l -10.5 26.9 C 473.4 443.7 472.2 445.4 470.6 446.5 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 82.75 1.8)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-507.95, -427)"
                            d="M 509.4 417.9 c -1.7 0 -3.2 0.4 -4.6 1.2 c -1.4 0.8 -2.4 1.9 -3.2 3.3 c -0.8 1.4 -1.1 3 -1.1 4.8 c 0 1.8 0.4 3.4 1.1 4.8 c 0.8 1.4 1.8 2.5 3.2 3.3 c 1.4 0.8 2.9 1.2 4.6 1.2 c 1.5 0 2.9 -0.3 4.2 -0.8 c 1.3 -0.5 2.4 -1.3 3.2 -2.4 l 1.7 1.8 c -1.1 1.3 -2.4 2.2 -4 2.9 c -1.6 0.7 -3.4 1 -5.3 1 c -2.3 0 -4.3 -0.5 -6.1 -1.5 c -1.8 -1 -3.2 -2.4 -4.2 -4.3 c -1 -1.8 -1.5 -3.9 -1.5 -6.2 c 0 -2.3 0.5 -4.3 1.5 -6.2 c 1 -1.8 2.4 -3.2 4.2 -4.3 c 1.8 -1 3.8 -1.5 6.1 -1.5 c 1.8 0 3.5 0.3 5 0.9 c 1.5 0.6 2.8 1.4 3.9 2.5 l -1.7 2 C 514.6 418.8 512.2 417.9 509.4 417.9 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 108.5 1.95)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-533.7, -427.15)"
                            d="M 539.9 416.7 c 1.8 1 3.2 2.4 4.3 4.2 c 1 1.8 1.5 3.9 1.5 6.2 c 0 2.3 -0.5 4.4 -1.5 6.2 c -1 1.8 -2.4 3.2 -4.3 4.3 c -1.8 1 -3.9 1.5 -6.2 1.5 c -2.3 0 -4.3 -0.5 -6.2 -1.5 c -1.8 -1 -3.2 -2.4 -4.3 -4.3 c -1 -1.8 -1.5 -3.9 -1.5 -6.2 c 0 -2.3 0.5 -4.3 1.5 -6.2 c 1 -1.8 2.4 -3.2 4.3 -4.2 c 1.8 -1 3.9 -1.5 6.2 -1.5 C 536 415.2 538.1 415.7 539.9 416.7 L 539.9 416.7 z M 529.1 419 L 529.1 419 c -1.4 0.8 -2.4 1.9 -3.2 3.3 c -0.8 1.4 -1.1 3 -1.1 4.8 c 0 1.8 0.4 3.5 1.1 4.9 c 0.8 1.4 1.8 2.5 3.2 3.3 c 1.4 0.8 2.9 1.2 4.6 1.2 c 1.7 0 3.3 -0.4 4.6 -1.2 c 1.4 -0.8 2.4 -1.9 3.2 -3.3 c 0.8 -1.4 1.1 -3 1.1 -4.9 c 0 -1.8 -0.4 -3.4 -1.1 -4.8 c -0.8 -1.4 -1.8 -2.5 -3.2 -3.3 c -1.4 -0.8 -2.9 -1.2 -4.6 -1.2 C 532 417.8 530.5 418.2 529.1 419 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 137.5 1.9)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-562.7, -427.1)"
                            d="M 571.1 417.7 c 1.6 1.6 2.4 3.9 2.4 6.8 V 439 h -3.1 v -13.8 c 0 -2.2 -0.6 -4 -1.9 -5.2 c -1.2 -1.3 -3 -1.9 -5.2 -1.9 c -2.4 0 -4.4 0.8 -5.9 2.3 c -1.5 1.5 -2.3 3.5 -2.5 6 V 439 h -3 v -23.7 h 3 v 5.5 c 0.8 -1.8 2 -3.2 3.6 -4.2 c 1.6 -0.9 3.5 -1.4 5.8 -1.4 C 567.3 415.2 569.5 416 571.1 417.7 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 167.1 1.9)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-592.3, -427.1)"
                            d="M 600.7 417.7 c 1.6 1.6 2.4 3.9 2.4 6.8 V 439 h -3.1 v -13.8 c 0 -2.2 -0.6 -4 -1.9 -5.2 c -1.2 -1.3 -3 -1.9 -5.2 -1.9 c -2.4 0 -4.4 0.8 -5.9 2.3 c -1.5 1.5 -2.3 3.5 -2.5 6 V 439 h -3 v -23.7 h 3 v 5.5 c 0.8 -1.8 2 -3.2 3.6 -4.2 c 1.6 -0.9 3.5 -1.4 5.8 -1.4 C 596.9 415.2 599.1 416 600.7 417.7 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 195 1.85)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-620.2, -427.05)"
                            d="M 628.9 418.7 c 1.9 2.3 2.8 5.5 2.7 9.4 h -19.8 c 0.2 2.5 1.1 4.6 2.8 6.1 c 1.7 1.6 3.7 2.3 6.2 2.3 c 1.5 0 2.9 -0.3 4.2 -0.8 c 1.3 -0.5 2.4 -1.3 3.3 -2.3 l 1.7 1.7 c -1.1 1.2 -2.5 2.2 -4.1 2.9 c -1.6 0.7 -3.4 1 -5.3 1 c -2.3 0 -4.3 -0.5 -6.1 -1.5 c -1.8 -1 -3.2 -2.4 -4.2 -4.2 c -1 -1.8 -1.5 -3.9 -1.5 -6.2 c 0 -2.3 0.5 -4.3 1.5 -6.2 c 1 -1.8 2.4 -3.2 4.2 -4.3 c 1.8 -1 3.8 -1.5 6 -1.5 C 624.1 415.2 626.9 416.4 628.9 418.7 L 628.9 418.7 z M 628.8 425.8 L 628.8 425.8 c -0.2 -2.5 -1 -4.4 -2.5 -5.9 c -1.5 -1.5 -3.4 -2.2 -5.9 -2.2 c -2.4 0 -4.4 0.7 -6 2.2 c -1.6 1.5 -2.5 3.4 -2.8 5.9 H 628.8 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 220.65 1.8)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-645.85, -427)"
                            d="M 647.3 417.9 c -1.7 0 -3.2 0.4 -4.6 1.2 c -1.4 0.8 -2.4 1.9 -3.2 3.3 c -0.8 1.4 -1.1 3 -1.1 4.8 c 0 1.8 0.4 3.4 1.1 4.8 c 0.8 1.4 1.8 2.5 3.2 3.3 c 1.4 0.8 2.9 1.2 4.6 1.2 c 1.5 0 2.9 -0.3 4.2 -0.8 c 1.3 -0.5 2.4 -1.3 3.2 -2.4 l 1.7 1.8 c -1.1 1.3 -2.4 2.2 -4 2.9 c -1.6 0.7 -3.4 1 -5.3 1 c -2.3 0 -4.3 -0.5 -6.1 -1.5 c -1.8 -1 -3.2 -2.4 -4.2 -4.3 c -1 -1.8 -1.5 -3.9 -1.5 -6.2 c 0 -2.3 0.5 -4.3 1.5 -6.2 c 1 -1.8 2.4 -3.2 4.2 -4.3 c 1.8 -1 3.8 -1.5 6.1 -1.5 c 1.8 0 3.5 0.3 5 0.9 c 1.5 0.6 2.8 1.4 3.9 2.5 l -1.7 2 C 652.5 418.8 650.1 417.9 647.3 417.9 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 242.75 -0.65)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-667.95, -424.55)"
                            d="M 675.2 437.4 c -1.6 1.1 -3.4 1.7 -5.2 1.8 c -1.8 0 -3.2 -0.6 -4.3 -1.7 c -1.1 -1.1 -1.6 -2.8 -1.6 -5 v -14.1 h -3.4 l 0 -2.4 h 3.4 v -6.1 h 3 v 6.1 l 7.7 0 v 2.4 h -7.7 v 13.6 c 0 2.9 1.1 4.4 3.3 4.4 c 1.2 0 2.5 -0.4 3.9 -1.3 L 675.2 437.4 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 -203.28 -2.31)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-221.92, -422.89)"
                            d="M 211.6 397.5 c 14 -5.7 30 1 35.8 15.1 c 4 9.7 1.9 20.4 -4.4 27.9 l -12.8 -31.5 c -0.9 -2.2 -3.4 -3.3 -5.6 -2.4 c -2.2 0.9 -3.3 3.4 -2.4 5.6 l 1.1 2.7 c 0.9 2.2 -0.2 4.7 -2.4 5.6 c -2.2 0.9 -4.7 -0.2 -5.6 -2.4 l -7.6 -18.7 C 209 398.7 210.3 398.1 211.6 397.5 L 211.6 397.5 z M 236.1 446.4 l -7.6 -18.7 c -0.9 -2.2 -3.4 -3.3 -5.6 -2.4 c -2.2 0.9 -3.3 3.4 -2.4 5.6 l 1.1 2.7 c 0.9 2.2 -0.2 4.7 -2.4 5.6 c -2.2 0.9 -4.7 -0.2 -5.6 -2.4 l -12.8 -31.5 c -6.3 7.5 -8.3 18.2 -4.4 27.9 c 5.7 14 21.7 20.8 35.8 15.1 C 233.6 447.8 234.9 447.2 236.1 446.4 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 -212.74 1.54)" id="_x23_Layer_1">
                        <path
                            style={{
                                stroke: "none",
                                strokeWidth: 1,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeDashoffset: 0,
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 4,
                                fill: "rgb(219,25,94)",
                                fillRule: "evenodd",
                                opacity: 1,
                            }}
                            transform=" translate(-212.46, -426.74)"
                            d="M 241.4 453.3 c 2.5 -1.6 5.8 -0.9 7.4 1.6 c 1.6 2.5 0.9 5.8 -1.6 7.4 c -1.2 0.8 -2.4 1.5 -3.7 2.1 c -1.4 0.7 -2.7 1.3 -3.9 1.8 c -12 4.9 -24.8 4.4 -35.8 -0.2 c -11 -4.7 -20.3 -13.5 -25.1 -25.5 c -4.1 -10.1 -4.4 -20.8 -1.6 -30.6 c 2.9 -10 9 -18.9 17.7 -25.1 c 2.4 -1.7 5.8 -1.2 7.5 1.3 c 1.7 2.4 1.2 5.8 -1.3 7.5 c -6.7 4.8 -11.5 11.7 -13.7 19.3 c -2.2 7.5 -1.9 15.8 1.3 23.6 c 3.7 9.2 10.9 16 19.4 19.6 c 8.5 3.6 18.4 3.9 27.6 0.2 c 1.2 -0.5 2.2 -0.9 3 -1.4 C 239.5 454.4 240.5 453.9 241.4 453.3 z"
                            strokeLinecap="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default HatcheryConnectLogo