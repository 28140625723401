import {ApiKey, MonthlyUsage, QrCodeObject, RedFile, SubscriptionRole, UrlDocument} from "../types/AppObjectTypes";
import {createContext, Dispatch, PropsWithChildren, useContext, useEffect, useState} from "react";
import {
    getAccountApiKeys,
    getAccountLinks,
    getAccountQrCodes,
    getAccountRedFiles,
    getMonthlyUsage
} from "../services/ConnectServices";
import {useAuthData} from "./AuthContext";
import {SubscriptionRoles} from "../library/constants/subscriptionRoles";

export interface AppDataContext{
    accountLinks:UrlDocument[]|[]
    setAccountLinks:Dispatch<UrlDocument[]|[]> |null
    fetchAccountLinks:() => void| Promise<void>
    accountQrCodes:QrCodeObject[]|null
    setAccountQrCodes:Dispatch<QrCodeObject[]|null>|null
    fetchAccountQrCodes:() => void|Promise<void>
    accountRedFiles:RedFile[]|[]
    setAccountRedFiles:Dispatch<RedFile[]|[]>|null
    fetchAccountRedFiles:() => void|Promise<void>
    fetchAccountApiKeys: () => void|Promise<void>
    setAccountApiKeys:Dispatch<ApiKey[]|[]>|null
    accountApiKeys:ApiKey[]|[]
    monthlyUsage:MonthlyUsage|null
    setMonthlyUsage:Dispatch<MonthlyUsage|null>|null
    fetchMonthlyUsage:() => void|Promise<void>
    subscriptionRole:SubscriptionRole|null
    setSubscriptionRole: Dispatch<null|SubscriptionRole>|null

}

export const AppDataContext = createContext<AppDataContext>({
    accountLinks:[],
    setAccountLinks:null,
    fetchAccountLinks:() => {},
    accountQrCodes:null,
    setAccountQrCodes:null,
    fetchAccountQrCodes:() => {},
    accountRedFiles:[],
    setAccountRedFiles:null,
    fetchAccountRedFiles: () => {},
    accountApiKeys:[],
    setAccountApiKeys: null,
    fetchAccountApiKeys:() => {},
    monthlyUsage:null,
    setMonthlyUsage: null,
    fetchMonthlyUsage:() => {},
    subscriptionRole: null,
    setSubscriptionRole:null
})

const useAppData = () => {
    const data = useContext(AppDataContext)
    if(!data){
        throw new Error("useAuthData must be called in App data context")
    }
    return data
}

type ConnectProviderProps = {
    children: PropsWithChildren<any>;
}

const ConnectProvider = ({children}:ConnectProviderProps) => {
    const [accountLinks, setAccountLinks] = useState<UrlDocument[]|[]>([])
    const [accountQrCodes, setAccountQrCodes] = useState<QrCodeObject[]|null>(null)
    const [accountRedFiles, setAccountRedFiles] = useState<RedFile[]|[]>([])
    const [accountApiKeys, setAccountApiKeys] = useState<ApiKey[]|[]>([])
    const [monthlyUsage, setMonthlyUsage] = useState<MonthlyUsage|null>(null)
    const [subscriptionRole, setSubscriptionRole] = useState<SubscriptionRole|null>(null)
    const {user,account} = useAuthData()

    const fetchAccountLinks = async () => {
        if(user){
            const links = await getAccountLinks(user.accountKey)
            setAccountLinks(links)
        }
    }
    const fetchAccountQrCodes = async () => {
        if(user){
            const qrCodes = await getAccountQrCodes(user.accountKey)
            setAccountQrCodes(qrCodes)
        }
    }

    const fetchAccountRedFiles = async () => {
        if(user){
            const rFiles = await getAccountRedFiles()
            setAccountRedFiles(rFiles)
        }
    }

    const fetchAccountApiKeys = async () => {
        if(user){
            const keys = await getAccountApiKeys()
            setAccountApiKeys(keys)
        }
    }

    const fetchMonthlyUsage = async () => {
        if(user){
            const monthly = await getMonthlyUsage()
            setMonthlyUsage(monthly)
        }
    }

    const getSubscriptionRole = () => {
        if(account){
           const subscriptionRoleFilter = SubscriptionRoles.filter(s => s.role === account.subscriptionRole)
            if(subscriptionRoleFilter.length > 0){
                const subRole = subscriptionRoleFilter[0]
                setSubscriptionRole(subRole)
            }else{
                console.log("error matching user subscription to role")
            }
        }
    }

//curious about promise.all does it effect pererformance by eliminating waterfall??
    const fetchAll = () => {
        fetchAccountLinks().catch(console.error)
        fetchAccountQrCodes().catch(console.error)
        fetchAccountRedFiles().catch(console.error)
        fetchAccountApiKeys().catch(console.error)
        fetchMonthlyUsage().catch(console.error)
    }
    useEffect(() => {
        fetchAll()
    },[user])

    useEffect(() => {
        getSubscriptionRole()
    },[account])

    return (
        <AppDataContext.Provider
            value={
                {
                    accountLinks,
                    setAccountLinks,
                    fetchAccountLinks,
                    accountQrCodes,
                    setAccountQrCodes,
                    fetchAccountQrCodes,
                    accountRedFiles,
                    setAccountRedFiles,
                    fetchAccountRedFiles,
                    accountApiKeys,
                    setAccountApiKeys,
                    fetchAccountApiKeys,
                    monthlyUsage,
                    setMonthlyUsage,
                    fetchMonthlyUsage,
                    subscriptionRole,
                    setSubscriptionRole
                }
            }
        >
            {children}
        </AppDataContext.Provider>
    )

}
export {useAppData, ConnectProvider}