import {useAuthData} from "../../../context/AuthContext";
import {useAppData} from "../../../context/AppDataContext";
import {ProgressBar} from "react-bootstrap";
import {useEffect, useState} from "react";


function QrMonthlyUsage(){
    const {account} = useAuthData()
    const {monthlyUsage,subscriptionRole,accountQrCodes} = useAppData()
    const [qrCap,setQrCap] = useState<number>(0)

    const findQrCap = () => {
        if(account && subscriptionRole){
           setQrCap(subscriptionRole.qrCap)
        }
    }

    useEffect(() => {
        findQrCap()
    },[account,subscriptionRole])

    //need to fetch qr codes made this month if sub_role not no_subscription
    const calcUsage = () => {
        if(account && monthlyUsage && accountQrCodes && subscriptionRole){
            if(subscriptionRole.role !== "no_subscription"){
                return Math.round((monthlyUsage.qrUsage/qrCap)*100)
            }else{
                return Math.round((accountQrCodes.length/qrCap)*100)
            }
        }
    }

    return (
        <>
            {account && account.subscriptionRole !== "no_subscription" && monthlyUsage && (
                <div className="w-75 text-black">
                    <p className="fs-5">{`Qr Codes: ${monthlyUsage.qrUsage}/${qrCap}`}</p>
                    <ProgressBar now={calcUsage()} variant={"secondary"}/>
                </div>
            )}
            {account && account.subscriptionRole == "no_subscription" && accountQrCodes && (
                <div className="w-75 text-black">
                    <p className="fs-5">{`Qr Codes: ${accountQrCodes.length}/${qrCap}`}</p>
                    <ProgressBar now={calcUsage()} variant={"secondary"}/>
                </div>
            )}
        </>
    )
}

export default QrMonthlyUsage