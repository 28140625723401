import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import {Amplify} from "aws-amplify";
import * as Sentry from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useNavigationType,useLocation} from "react-router";

const userpoolId = import.meta.env.VITE_PUBLIC_USERPOOL_ID ||""
const userpoolClientId = import.meta.env.VITE_PUBLIC_USERPOOL_CLIENT_ID

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: userpoolId,
            userPoolClientId:userpoolClientId,
            identityPoolId: "",
            loginWith: {
                email: true,
                oauth: {
                    domain: import.meta.env.VITE_PUBLIC_COGNITO_DOMAIN, // Your Cognito domain
                    scopes : ['email', 'profile', 'openid',"aws.cognito.signin.user.admin"],
                    redirectSignIn: [`${import.meta.env.VITE_PUBLIC_DOMAIN}/oauth-signin`],
                    redirectSignOut: [`${import.meta.env.VITE_PUBLIC_DOMAIN}/logout`],
                    responseType: 'code', // 'code' for Authorization code grant, 'token' for Implicit grant
                },
            },
            signUpVerificationMethod: "code",
            userAttributes: {
                email: {
                    required: true,
                },
            },
            allowGuestAccess: true,
            passwordFormat: {
                minLength: 8,
                requireLowercase: true,
                requireUppercase: true,
                requireNumbers: true,
                requireSpecialCharacters: true,
            },

            // ??unclear about how to set some of this up?
            // Add Google as an OAuth provider
        },
    },
})


Sentry.init({
    dsn: "https://bad2f4c2266df130381086f4babce081@o4508054891003904.ingest.us.sentry.io/4508054918397952",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    environment: import.meta.env.VITE_PUBLIC_ENV,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/dev-app.hatchery-connect\.com/, /^https:\/\/app.hatchery-connect\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
