import DefaultLayout from "../library/components/layout/DefaultLayout";


function CustomLinksPage(){


    return (
        <>
            <DefaultLayout>

            </DefaultLayout>
        </>
    )
}

export default CustomLinksPage