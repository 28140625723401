import {useLayoutData} from "../../../context/LayoutContext";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileOffCanvas from "./MobileOffCanvas";
import {useState} from "react";


type Props = {
    children?: React.ReactNode
    justify?:"start"|"center"|"end"|undefined
    align?:"start"|"center"|"end"|undefined
};
function MainDisplay({children,justify,align}:Props){
    const { toggleMenu,toggleMode, layoutConfig} = useLayoutData()
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
    const determineJustify = () => {
        if(justify){
            switch (justify){
                case "center":
                    return "justify-content-center"
                case "end":
                    return "justify-content-end"
                case "start":
                    return "justify-content-start"
            }
        }else{
            return "justify-content-center"
        }
    }
    const determineAlign = () => {
        if(align){
            switch (align){
                case "center":
                    return "align-items-center"
                case "end":
                    return "align-items-end"
                case "start":
                    return "align-items-start"
            }
        }else{
            return "align-items-center"
        }
    }


    return (
        <>
            {layoutConfig ? (
                <div className={ "c-main-display-wrapper mt-5"}>
                    {/*Layer 5 - Main column - display*/}
                    {/*add right margin if no right side elements*/}
                    {(layoutConfig.includeRightSideBar || layoutConfig.includeRightSubMenu)? (
                        <div id="c-main-display" datatype="menu"
                             className={`c-main-display c-d-flex  bg-s rounded-4 ${determineAlign()} ${determineJustify()}`}>
                            {children}
                        </div>
                    ):(<div id="c-main-display" datatype="menu"
                            className={`c-main-display c-d-flex  bg-s rounded-4 ${determineAlign()} ${determineJustify()} me-lg-5 mx-4 px-4`}>
                        {children}
                    </div>)}
                    {/*Layer 5 - Main column - display popup (code editor, logs, etc)*/}
                    <div id="c-main-display-popup" datatype="menu" className="c-main-display-popup   bg-p">
                        {/*Layer 5 - Main column - display popup submenus*/}
                        {/* Popup Menu 1*/}
                        {layoutConfig.includeMainPopup1 && (

                            <div id="c-main-display-popup-submenu-0" datatype="c-main-display-popup-submenu"
                            className="c-main-display-popup-submenu">
                        {/*<div id="codeEditor" className="codeEditor"></div>*/}
                            Popup 1
                            </div>
                        )}

                        {/*Popup Menu 2*/}
                        {layoutConfig.includeMainPopup2 && (
                            <div id="c-main-display-popup-submenu-1" datatype="c-main-display-popup-submenu"
                                 className="c-main-display-popup-submenu">
                                Popup 2
                            </div>
                        )}

                        {/*Popup Menu 3*/}
                        {layoutConfig.includeMainPopup3 && (
                            <div id="c-main-display-popup-submenu-2" datatype="c-main-display-popup-submenu"
                                 className="c-main-display-popup-submenu">
                                Popup 3
                            </div>
                        )}

                        {/*Popup Menu 4*/}
                        {layoutConfig.includeMainPopup4 && (
                            <div id="c-main-display-popup-submenu-3" datatype="c-main-display-popup-submenu"
                                 className="c-main-display-popup-submenu">
                                Popup 4
                            </div>
                        )}

                    </div>
                    {/*Layer 5 - Main column - display footer*/}
                    {layoutConfig.includeMainPopupNav && (
                        <nav id="c-main-display-footer" datatype="menu"
                             className="c-main-display-footer c-d-flex   justify-content-between align-items-center">
                            <ul className="c-d-flex gap-1">
                                {layoutConfig.includeMainPopup1 && (
                                    <li>
                                        <button id="c-main-display-popup-submenu-btn-0"
                                                datatype="c-main-display-popup-submenu-btn"
                                                onClick={() => toggleMenu('c-main-display-popup',0)}
                                                className="c-main-display-popup-btn   p-3" aria-label="Button name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
                                                <path
                                                    d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                                <path
                                                    d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                            </svg>

                                            <span className="ps-1">Code</span>
                                        </button>
                                    </li>
                                )}
                                {layoutConfig.includeMainPopup2 && (
                                    <li>
                                        <button id="c-main-display-popup-submenu-btn-1"
                                                datatype="c-main-display-popup-submenu-btn"
                                                onClick={() => toggleMenu('c-main-display-popup',1)}
                                                className="c-main-display-popup-btn   p-3" aria-label="Button name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
                                                <path
                                                    d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                                <path
                                                    d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                            </svg>

                                            <span className="ps-1">Log</span>
                                        </button>
                                    </li>
                                )}
                            </ul>
                            <ul className="c-d-flex gap-1">
                                {layoutConfig.includeMainPopup3 && (
                                    <li>
                                        <button id="c-main-display-popup-submenu-btn-2"
                                                datatype="c-main-display-popup-submenu-btn"
                                                onClick={() => toggleMenu('c-main-display-popup',2)}
                                                className="c-main-display-popup-btn   p-3" aria-label="Button name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
                                                <path
                                                    d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                                <path
                                                    d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                            </svg>

                                        </button>
                                    </li>
                                )}
                                {layoutConfig.includeMainPopup4 && (
                                    <li>
                                        <button id="c-main-display-popup-submenu-btn-3"
                                                datatype="c-main-display-popup-submenu-btn"
                                                onClick={() => toggleMenu('c-main-display-popup',3)}
                                                className="c-main-display-popup-btn   p-3" aria-label="Button name">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
                                                <path
                                                    d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                                <path
                                                    d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                            </svg>

                                        </button>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    )}
                    {/*Main - mobile footer  */}
                    <nav id="c-main-mobile-footer" datatype="menu"
                         className="c-main-mobile-footer   c-d-flex justify-content-between align-items-center mt-2 p-2">
                        <a className="c-btn icon" href="/">
                            <img src="/favicon_package_v0.16/apple-touch-icon.png" className="img-fluid"
                                 style={{maxWidth: "100%", height:"auto"}} alt={""}/>
                        </a>
                        <ul className="c-d-flex">
                            <li>
                                <button aria-label="Button name" className="icon" onClick={() => setShowMobileMenu(true)}>
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                                    {/*     fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">*/}
                                    {/*    <path*/}
                                    {/*        d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>*/}
                                    {/*    <path*/}
                                    {/*        d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>*/}
                                    {/*</svg>*/}
                                    <div className="text-black">
                                        <RxHamburgerMenu size={20}/>
                                    </div>

                                </button>
                            </li>
                            {/*<li>*/}
                            {/*    <button aria-label="Button name" className="icon">*/}
                            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                            {/*             fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">*/}
                            {/*            <path*/}
                            {/*                d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>*/}
                            {/*            <path*/}
                            {/*                d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>*/}
                            {/*        </svg>*/}

                            {/*    </button>*/}
                            {/*</li>*/}
                            <li>
                                <button onClick={() => toggleMode()} aria-label="Button name" className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-brightness-high-fill"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
                                    </svg>

                                </button>
                            </li>
                        </ul>
                    </nav>
                    <MobileOffCanvas showState={showMobileMenu} setShowState={setShowMobileMenu}/>
                </div>
            ):<></>}
        </>
    )
}

export default MainDisplay