import {useLayoutData} from "../../../context/LayoutContext";


function RightSideBar(){
const {toggleMenu, layoutConfig} = useLayoutData()

    return(
        <>
            {(layoutConfig && layoutConfig.includeRightSideBar) && (
                <nav id="c-main-btn-col" datatype="menu"
                     className="c-main-btn-col  c-d-flex flex-column align-items-center">
                    <ul className="c-d-flex flex-column gap-1">
                        <li>
                            <button id="c-main-tools-submenu-btn-0" datatype="c-main-tools-submenu-btn"
                                    onClick={() => toggleMenu('c-main-tools',0)} className="c-main-tools-btn   icon"
                                    aria-label="Button name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-stack" viewBox="0 0 16 16">
                                    <path
                                        d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                    <path
                                        d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                </svg>

                            </button>
                        </li>
                        <li>
                            <button id="c-main-tools-submenu-btn-1" datatype="c-main-tools-submenu-btn"
                                    onClick={() => toggleMenu('c-main-tools',1)} className="c-main-tools-btn   icon"
                                    aria-label="Button name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-stack" viewBox="0 0 16 16">
                                    <path
                                        d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                    <path
                                        d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                </svg>

                            </button>
                        </li>
                        <li>
                            <button id="c-main-tools-submenu-btn-2" datatype="c-main-tools-submenu-btn"
                                    onClick={() => toggleMenu('c-main-tools',2)} className="c-main-tools-btn   icon"
                                    aria-label="Button name">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-stack" viewBox="0 0 16 16">
                                    <path
                                        d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
                                    <path
                                        d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
                                </svg>

                            </button>
                        </li>
                    </ul>
                </nav>
            )}
        </>
    )
}

export default RightSideBar