import LeftSideBar from "./LeftSideBar";
import LeftSubMenu from "./LeftSubMenu";
import MainDisplay from "./MainDisplay";
import RightSubMenu from "./RightSubMenu";
import TopBar from "./TopBar";
import RightSideBar from "./RightSideBar";
import {ReactNode, useEffect} from "react";
import {useLayoutData} from "../../../context/LayoutContext";
import {Outlet} from "react-router";
import Footer from "./Footer";

type Props = {
    children?: ReactNode,
    pageTitle?:string|undefined
    justify?:"start"|"center"|"end"
    align?:"start"|"center"|"end"
};
function DefaultLayout({children,pageTitle,justify,align}:Props){
    const {loadAllStates} = useLayoutData()
    useEffect(() => {
        loadAllStates()
    },[])

    return (
        <main id="c-viewport" className="c-viewport">
            {/* Layer 2 - header*/}
            <TopBar pageTitle={pageTitle}/>
            {/*END - Layer 2 - header*/}

            {/*Layer 2 - wrapper*/}
            <div className="c-wrapper">
                {/*Layer 3 - Button column (left)*/}
                <LeftSideBar/>
                {/*Layer 3 - Elements column*/}
                <LeftSubMenu/>
                {/*Layer 3 - Main column*/}
                <section id="c-main" className="c-main">
                    {/*Layer 4 - Main column - interactive display wrapper*/}
                    <MainDisplay justify={justify} align={align}>
                        {children}
                    </MainDisplay>
                    {/*Layer 4 - Main column - tools*/}
                    <RightSubMenu/>
                    {/*Layer 4 - Main column - button column (right)*/}
                    <RightSideBar/>
                </section>
            </div>
           <div className="mb-2">
               <Footer/>
           </div>
        {/*     END - Layer 2 - wrapper*/}
        </main>
    )
}

export default DefaultLayout