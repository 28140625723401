import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {useLocalStorage} from "../library/hooks/useLocalStorage";
import {DarkTheme, LayoutConfiguration, LightMode, LightTheme, MenuState, SubMenuState} from "../types/LayoutTypes";
import {
    lightTheme,
    darkTheme,
    initialLightMode,
    initialMenuState,
    initialSubMenuState,
    initialTagMode, layoutConfiguration
} from "../library/constants/layout";


interface LayoutContext {
    //toggle functions
    toggleMode: () => void ,
    toggleMenu: (menu:string|string[],subMenu:number|object) => void,
    toggleTag: () => void
    //state variables
    menuState: MenuState|null
    subMenuState: SubMenuState|null
    lightMode:boolean
    tagMode:boolean
    layoutConfig:LayoutConfiguration|null
    loadAllStates: () => void
}

export const LayoutContext = createContext<LayoutContext>({
    //read constants file for defaults
    toggleMode: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleMenu: (menu, subMenu) => {},
    toggleTag: () => {},
    menuState: null,
    subMenuState: null,
    tagMode: true,
    lightMode:true,
    layoutConfig:null,
    loadAllStates: () => {}

})

const useLayoutData = () => {
    const data = useContext(LayoutContext)
    if(!data){
        throw new Error("useLayoutData must be called in LayoutProvider")
    }
    return data
}

export type LayoutProviderProps = {
    children: PropsWithChildren<any>;
}

const LayoutProvider = ({children}:LayoutProviderProps) => {
    const { setItem, getItem } = useLocalStorage();
    const [lightMode,setLightMode] = useState<LightMode>(true)
    const [menuState, setMenuState] = useState<MenuState>(initialMenuState)
    const [subMenuState ,setSubMenuState] = useState<SubMenuState>(initialSubMenuState)
    const [tagMode, setTagMode] = useState<boolean>(false)
    const [layoutConfig, setLayoutConfig] = useState<LayoutConfiguration>(layoutConfiguration)

    //load states from localstorage or initial constants
    const loadLightMode = () => {
        try{
           const localLightMode = getItem("lightMode")
            if(localLightMode){
                //@ts-ignore
                applyTheme(JSON.parse(localLightMode))
                setLightMode(JSON.parse(localLightMode))
            }else{
                setItem("lightMode",JSON.stringify(initialLightMode))
                //@ts-ignore
                applyTheme(JSON.parse(localLightMode))
                setLightMode(initialLightMode)
            }
        }catch (e){

        }
    }
    const loadMenuState = () => {
        try{
            const localMenuState = getItem("menuState")
            if(localMenuState){
                applyMenuState(JSON.parse(localMenuState))
                setMenuState(JSON.parse(localMenuState))
            }else{
                setItem("menuState",JSON.stringify(initialMenuState))
                applyMenuState(initialMenuState)
                setMenuState(initialMenuState)
            }
        }catch(e){
            console.log("my error",e)
        }
    }
    const loadTagMode = () => {

        try{
            const localTagMode = getItem("tagMode")
            if(localTagMode){
                applyTagMode(JSON.parse(localTagMode))
                setTagMode(JSON.parse(localTagMode))
            }else{
                setItem("tagMode",JSON.stringify(initialTagMode))
                //applyTheme(JSON.parse(localMenuState))
                setTagMode(initialTagMode)
            }
        }catch(e){

        }
    }
    const loadSubMenuState = () => {
        try{
            const localSubMenuState = getItem("subMenuState")
            if(localSubMenuState){
                applyMenuState(JSON.parse(localSubMenuState))
                setSubMenuState(JSON.parse(localSubMenuState))
            }else{
                setItem("subMenuState",JSON.stringify(initialSubMenuState))
                applyMenuState(initialSubMenuState)
                setSubMenuState(initialSubMenuState)
            }
        }catch(e){
            console.log("my error",e)
        }
    }
    //apply state values to dom css
    const applyTheme = (state:boolean) => {
        let theme:LightTheme | DarkTheme;
        state ? theme = lightTheme : theme = darkTheme;
        //Object.keys??
        // Set CSS vars
        const root = document.documentElement;
        // //@ts-ignore
        // for (let key in theme) {
        //     root.style.setProperty(key, theme[key]);
        // }
        Object.entries(theme).forEach((entry) => {
            root.style.setProperty(entry[0],entry[1])
        })
    }
    const applyMenuState = (state:MenuState|SubMenuState) => {
        const menuIDs = getDataType(document,'menu')
        for (let menuID in state) {
            toggleDisplayCSS(menuID,menuIDs,menuState,subMenuState);
        };
    }
    const applyTagMode = (state:boolean) => {
        const tags = document.getElementsByClassName("div-tag");
        // Toggle class for visibility
        if(state){
            for (let i = 0; i < tags.length; i++) {
                tags[i].classList.add("on");
            };
        }else{
            for (let i = 0; i < tags.length; i++) {
                tags[i].classList.remove("on");
            };
        };
    }
    //toggle functions to be used for onclick
    const toggleMode = function toggleMode(){
        setItem("lightMode",JSON.stringify(!lightMode))
        applyTheme(!lightMode)
        setLightMode(prevState => !prevState)
    }
    const toggleMenu = function toggleMenu(menu:string[]|string,subMenu:number|{}){
        //console.log("toggleMenu",menu)
        let menuIDs = getDataType(document,'menu')

        //deep clone menuState/subMenuState objects
        const menuStateClone = structuredClone(menuState)
        const subMenuStateClone = structuredClone(subMenuState)
        // If toggling multiple menus simultaneously
        if(Array.isArray(menu)){
            for (let i = 0; i < menu.length; i++) {
                //@ts-ignore
                menuStateClone[menu[i]] = !menuStateClone[menu[i]];
                // Does not yet support submenus!
                toggleDisplayCSS(menu[i],menuIDs,menuStateClone,subMenuStateClone);
            }
        }
        // If toggling one menu
        else if(typeof menu === "string"){
            if(typeof subMenu === 'number'){//If has submenus (is part of menu toggle group)
                //@ts-ignore
                if(subMenuStateClone[menu] === subMenu){//If clicked open menu
                    //Toggle open menu
                    //@ts-ignore
                    menuStateClone[menu] = !menuStateClone[menu];

                }else{//If clicked closed menu
                    //Update menu contents
                    //@ts-ignore
                    subMenuStateClone[menu] = subMenu;
                    //@ts-ignore
                    if(menuStateClone[menu] === false){//If wrapper menu is closed
                        //Open wrapper menu
                        //@ts-ignore
                        menuStateClone[menu] = true;
                    };
                };
            }else{//Is independent menu without submenus
                //@ts-ignore
                menuStateClone[menu] = !menuStateClone[menu];
            };
            // toggleDisplayCSS(menu,menuIDs);
            toggleDisplayCSS(menu,menuIDs,menuStateClone,subMenuStateClone)
        }

            //Is first-time user without localStorage initialized
        //dont think I need this
        else{
            for (let i = 0; i < menuIDs.length; i++) {
                let menu = menuIDs[i];
                toggleDisplayCSS(menu,menuIDs,menuStateClone,subMenuStateClone);
            }
        }

        // Update local storage
        setItem("menuState",JSON.stringify(menuStateClone))
        setItem("subMenuState",JSON.stringify(subMenuStateClone))
        setMenuState(menuStateClone)
        setSubMenuState(subMenuStateClone)

    }
    const toggleTag = function toggleTag(){

        setItem("tagMode",JSON.stringify(!tagMode))
        applyTagMode(!tagMode)
        setTagMode(prevState => !prevState)
    };
    // Toggle Display CSS
    function toggleDisplayCSS(menuID:string,menuIDs:string[],menuS:MenuState,subMenuS:SubMenuState){

        //Update parent menu
        let display = ""
        //@ts-ignore
        menuS[menuID] === false ? display = "none" : display = "flex";
        if(menuIDs.includes(menuID)){
            const menu = window.document.getElementById(menuID);
            if(menu){
                menu.style.display = display;
            }
            //Check and update submenu and button
            const subMenuIDs = getDataType(menu,`${menuID}-submenu`)
            if (subMenuIDs.length > 0) {
                //@ts-ignore
                const key = subMenuS[menuID] ?? 0;
                const subMenuBtnIDs = getDataType(document,`${menuID}-submenu-btn`)
                for (let i = 0; i < subMenuIDs.length; i++) {
                    const subMenu = document.getElementById(subMenuIDs[i]);
                    const subMenuBtn = document.getElementById(subMenuBtnIDs[i]);
                    if(subMenu){
                        i == key ? subMenu.style.display = "block" : subMenu.style.display = "none";
                    }
                    if(subMenuBtn !== null){
                        //@ts-ignore
                        i === key && menuS[menuID] ? subMenuBtn.classList.add("active") : subMenuBtn.classList.remove("active");
                    }
                };
            };
        };
    };
    // get dom elements based on datatype and parent element
    function getDataType(parent:any,type:any){
        // Get all elements with data-type="menu"
        const menuElements = parent.querySelectorAll(`[datatype="${type}"]`);
        // Extract and display the IDs of the matched elements
        //@ts-ignore
        return  Array.from(menuElements).map((element:HTMLElement) => element.id);
    };
    //-- Load all states
    const  loadAllStates = useCallback(() => {
        loadLightMode()
        loadMenuState()
        loadSubMenuState()
        loadTagMode()
    },[loadLightMode, loadMenuState, loadTagMode,loadSubMenuState]);
    useEffect(() => {
        //load state variables from local storage
        loadAllStates()
    },[])

    return (
        <LayoutContext.Provider
            value={{toggleMode,
                toggleMenu,
                toggleTag,
                menuState,
                subMenuState,
                tagMode,
                lightMode,
                layoutConfig,
                loadAllStates
            }}
        >
            {children}
        </LayoutContext.Provider>
    )
}
export { useLayoutData,LayoutProvider}

